import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import faker from 'faker';
import randomColor from "randomcolor";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: true,
      text: 'Total de Depositos de Afiliados por Dia',
    },
  },
  scales: {
    y: {
      type: 'linear',
      display: true,
      position: 'left',
    }
  },
};





const labels = [
  '01/08/2023','02/08/2023','03/08/2023','04/08/2023','05/08/2023','06/08/2023','07/08/2023','08/08/2023','09/08/2023','10/08/2023','11/08/2023','12/08/2023','13/08/2023','14/08/2023','15/08/2023','16/08/2023','17/08/2023','18/08/2023','19/08/2023','20/08/2023','21/08/2023','22/08/2023','23/08/2023','24/08/2023','25/08/2023','26/08/2023','27/08/2023','28/08/2023','29/08/2023','30/08/2023','31/08/2023' 
]
const labels2 = [
  '2023-08-01',
  '2023-08-02',
  '2023-08-03',
  '2023-08-04',
  '2023-08-05',
  '2023-08-06',
  '2023-08-07',
  '2023-08-08',
  '2023-08-09',
  '2023-08-10',
  '2023-08-11',
  '2023-08-12',
  '2023-08-13',
  '2023-08-14',
  '2023-08-15',
  '2023-08-16',
  '2023-08-17',
  '2023-08-18',
  '2023-08-19',
  '2023-08-20',
  '2023-08-21',
  '2023-08-22',
  '2023-08-23',
  '2023-08-24',
  '2023-08-25',
  '2023-08-26',
  '2023-08-27',
  '2023-08-28',
  '2023-08-29',
  '2023-08-30',
  '2023-08-31'
]
const data3 = {
  "Agencia":[55.00,32.50,20.00,75.00,40.00,10.00,362.00,56.00,21.00,92.00,10.00,4.00,1.00,265.00,6.50,45.00,25.00,50.00,500.00,10.00,60.00,0.00,10.00,127.50,0.00,0.00,0.00,0.00,102.00,5.00,0.00],
  //"aureabia":[69.60,50.00,13.00,45.00,80.00,10.00,6.44,22.00,21.00,10.00,0.00,20.00,10.00,11.00,68.00,0.00,1.00,2.00,10.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,53.00,10.00,48.00,20.00,4.00],
  //"biaolivetti44":[21.00,24.90,15.00,70.00,35.00,137.00,32.00,86.00,8.00,0.00,0.00,11.00,11.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,50.00,11.00,0.00],
  //"Brendasousa1408":[75.00,95.00,20.00,41.00,2.00,65.00,130.00,90.00,0.00,20.00,0.00,24.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00],
  ////"buzanga":[95.00,78.00,41.00,65.00,62.60,71.41,10.00,45.41,22.00,66.00,23.00,72.00,3.00,184.00,39.00,0.00,0.00,26.00,20.00,60.00,26.00,22.00,30.00,0.00,45.00,10.00,13.00,20.00,65.00,0.00,0.00],
  ////"charlesbarros":[284.00,212.50,75.00,198.00,30.00,90.00,125.00,185.00,90.00,30.00,2.00,0.00,25.00,350.00,25.00,10.00,40.00,30.00,2.00,20.00,0.00,80.00,100.00,21.50,40.00,139.98,135.00,62.00,21.23,88.40,95.00],
  //"gmr":[0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,8.00,0.00,1.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00],
  //"joaogestor":[0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,2.50,20.00,20.00,25.00,0.00,2.00,51.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00],
  //"joaolemozz":[0.00,0.00,0.00,0.00,0.00,50.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,50.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,11.00,0.00,0.00,0.00,27.00,45.00,10.00,15.00,0.00],
  //"Kerolym009":[10.00,20.00,0.00,20.00,0.00,0.00,0.00,55.00,255.00,10.00,0.00,0.00,100.00,10.00,20.00,40.00,40.00,0.00,0.00,0.00,40.00,0.00,10.00,10.00,0.00,20.00,25.00,0.00,0.00,0.00,0.00],
  //"kevinads":[20.00,10.00,2.00,11.00,15.00,0.00,54.00,20.00,10.00,0.00,10.00,60.00,110.00,10.00,11.00,0.00,10.00,20.00,0.00,20.00,27.52,57.00,0.00,20.00,10.00,45.00,0.00,22.00,20.00,0.00,20.00],
  "Lab64":[50.00,274.00,248.00,112.00,26.00,25.00,122.60,131.50,6.00,0.00,12.00,52.00,44.50,2.00,15.00,43.00,59.00,6.26,50.00,15.00,20.00,2.00,1.00,100.00,18.00,0.00,0.00,14.00,0.00,1.00,0.00],
  //"madson":[10.00,0.00,1.00,33.00,1.00,31.00,10.00,117.00,88.00,38.00,10.00,28.00,20.00,40.00,20.00,48.00,41.00,43.00,101.20,45.00,33.65,10.00,2.00,5.00,51.00,2.00,39.65,18.00,67.00,112.00,65.00],
  //"marcelobarman":[50.00,20.00,22.00,45.00,50.00,100.00,140.00,115.00,84.00,140.00,90.00,131.00,104.40,62.00,70.00,10.00,10.00,20.00,26.00,10.00,0.00,34.00,21.00,20.00,56.00,44.40,0.00,0.00,10.00,5.50,0.00],
  //"mateusslz":[0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,10.00,28.00,47.00,0.00],
  //"palomaesport":[0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,20.00,0.00,0.00,0.00,20.00,0.00,0.00,0.00,0.00,30.00,70.00,190.00,176.00,60.00,22.00,8.00,15.00,31.00,25.00,40.00,24.00,28.00],
  //"ruthcastro23":[0.00,10.00,0.00,15.00,10.00,10.00,0.00,0.00,0.00,100.00,0.00,0.00,10.00,0.00,10.00,0.00,0.00,0.00,50.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,30.00,15.00,12.00,10.00],
  //"viveiros12":[0.00,0.00,40.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,12.00,0.00,0.00,20.00,0.00,20.00,18.00,60.00,32.00,0.00,0.00,0.00,0.00,27.00,33.00,3.00,50.00,20.00,0.00,0.00,8.00],
  //"welttrafegopago":[10.00,10.00,0.00,40.00,0.00,20.00,5.00,0.00,0.00,52.00,0.00,10.00,0.00,10.00,0.00,0.00,200.00,20.00,1.00,0.00,11.00,2.00,1.00,1.00,0.00,90.00,6.00,12.00,0.00,16.14,28.00],
};
export function Linegraph() {
  let idata = Object.keys(data3).map(x => {
    console.log(x);
    let color = randomColor();
    return {
      label:x,
      data:data3[x],
      tension:0.1,
      fill:false,
      backgroundColor: color,
      borderColor: color,
      borderWidth:3,
      
    }
  })
  console.log(idata);
  return <Line options={options} data={{
    labels, datasets:idata}} />;
}
