import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: 'Chart.js Bar Chart - Stacked',
    },
  },
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const labels = [ 'MAIO', 'JUNHO', 'JULHO', 'AGOSTO'];

export const data = {
    labels,
    datasets: [
      {
        label: 'LAB64',
        data: [1463	, 5869	, 3276], 
        backgroundColor: 'rgb(255, 99, 132)',
        stack: 'Stack 0',
      },
      {
        label: 'charlesbarros',
        data: [3094	, 1041	, 712],
        backgroundColor: 'rgb(75, 192, 192)',
        stack: 'Stack 1',
      },
      {
        label: 'marcelobarman',
        data: [505	, 524	, 174],
        backgroundColor: 'rgb(53, 162, 235)',
        stack: 'Stack 2',
      },
      {
        label: 'Agencia',
        data: [260	, 283	, 239],
        backgroundColor: 'rgb(255, 162, 235)',
        stack: 'Stack 3',
      },
    ],
  };
  //[1463	, 5869	, 3276], "R$ 8.109,31 - R$ 174.255,45 - R$ 174.255,45";					
  //[3094	, 1041	, 712], "R$ 139.834,24 - R$ 44.150,48 - R$ 44.150,48";					
  //[505	, 524	, 174], "R$ 15.812,65 - R$ 11.573,24 - R$ 11.573,24";					
  //[260	, 283	, 239], "R$ 12.205,35 - R$ 17.090,72 - R$ 17.090,72";					
export function Bargraph() {
  return <Bar options={options} data={data} />;
}
