//import logo from './logo.svg';

import { AppBar, Button, Toolbar, Typography } from '@mui/material';
import './App.css';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import * as faker from 'faker';
import Dashboard from './Dashboard';







function App() {
  return <Dashboard />;
}

export default App;
